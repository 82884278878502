export const SET_SHORT_SURAH_LOADING = "SET_SHORT_SURAH_LOADING";
export const SHORT_SURAH_LIST_GET_SUCCESS = "SHORT_SURAH_LIST_GET_SUCCESS";

export const SET_FULL_SURAH_LOADING = "SET_FULL_SURAH_LOADING";
export const FULL_SURAH_GET_SUCCESS = "FULL_SURAH_GET_SUCCESS";

export const SET_TAFSIR_LOADING = "SET_TAFSIR_LOADING";
export const GET_TAFSIR_SUCCESS = "GET_TAFSIR_SUCCESS";

export const SAVE_TO_READ_LATER = "SAVE_TO_READ_LATER";
export const SET_LAST_READ_SURAH = "SET_LAST_READ_SURAH";
export const SET_LAST_READ_AYAH = "SET_LAST_READ_AYAH";
export const SET_ARABIC_TEXT_SIZE = "SET_ARABIC_TEXT_SIZE";
export const SET_BANGLA_TEXT_SIZE = "SET_BANGLA_TEXT_SIZE";
export const SET_ENGLISH_TEXT_SIZE = "SET_ENGLISH_TEXT_SIZE";
export const SET_TAZWEED = "SET_TAZWEED";
export const SET_HAFEZI_FONT = "SET_HAFEZI_FONT";
