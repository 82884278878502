import Nabvar from "./Nabvar";

const TopNavbar = () => {
  return (
    <>
      <Nabvar />
    </>
  );
};

export default TopNavbar;
